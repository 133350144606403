import { useDialog } from "@providers/DialogProvider";
import { useMutation } from "@tanstack/react-query";
import classNames from "classnames";
import { useTranslation } from "next-i18next";
import { FormEvent, useRef, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { sendContactForm } from "../../../data/contact/contact.data";
import classes from "./ContactForm.module.scss";
import ContactFormDialog from "./ContactFormDialog";
import TrashSVG from "../../../public/icons/trash-fill.svg";

export interface ContactFormProps {
  variant: "hr" | "business";
}

const ContactForm: React.FC<ContactFormProps> = ({ variant }) => {
  const { openDialog, closeDialog } = useDialog();
  const { t } = useTranslation("contact");
  const [validated, setValidated] = useState(false);
  const formRef = useRef<HTMLFormElement | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    setSelectedFile(file);
  };

  const toggleSuccessDialog = () => {
    openDialog({
      id: "navigation-contact-dialog",
      contentClassName: "bg-dark pt-5 px-5",
      content: <ContactFormDialog variant="success" />,
      size: "md",
      animation: false,
      centered: true,
      className: "m-0 p-0",
    });
  };

  const toggleErrorDialog = () => {
    openDialog({
      id: "navigation-contact-dialog",
      contentClassName: "bg-dark pt-5 px-5",
      content: <ContactFormDialog variant="error" />,
      size: "md",
      animation: false,
      centered: true,
      className: "m-0 p-0",
    });
  };

  const mutation = useMutation(sendContactForm, {
    onSuccess: () => {
      toggleSuccessDialog();
      formRef?.current?.reset();
      setValidated(false);
    },
    onError: () => {
      toggleErrorDialog();
      setValidated(false);
    },
  });

  const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
    const form = event.currentTarget;
    event.preventDefault();

    setValidated(true);

    if (form.checkValidity() === false) {
      event.stopPropagation();
      return;
    }

    const formData = new FormData(form);

    await mutation.mutate(formData);
    closeDialog();
  };

  return (
    <form
      ref={formRef}
      className={`text-center needs-validation ${
        validated ? "was-validated" : ""
      }`}
      onSubmit={submitHandler}
      noValidate
    >
      <div className="position-relative d-md-flex">
        <div className="mb-3 w-100">
          <input
            name="name"
            type="text"
            placeholder={t("form.name")}
            required
            className={classNames(
              "form-control",
              classes.contactForm__formInput
            )}
          />
        </div>
      </div>

      <div className="position-relative d-md-flex">
        <div className="mb-3 me-md-2 w-100">
          <input
            name="email"
            type="email"
            placeholder={t("form.email")}
            required
            pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"
            className={classNames(
              "form-control",
              classes.contactForm__formInput
            )}
          />
        </div>

        <div className="mb-3 w-100 ms-md-2">
          <input
            name="phone"
            type="text"
            pattern="\+?\d+(?:\s*\d+)*"
            placeholder={t("form.phone")}
            required
            className={classNames(
              "form-control",
              classes.contactForm__formInput
            )}
          />
        </div>
      </div>

      <div className="d-md-flex">
        {variant === "business" ? (
          <div className="mb-3 w-100">
            <input
              name="firmName"
              type="text"
              placeholder={t("form.firm_name")}
              className={classNames(
                "form-control",
                classes.contactForm__formInput
              )}
            />
          </div>
        ) : (
          <div className="mb-3 w-100">
            <label
              htmlFor="cvFile"
              className={classNames(
                "form-control",
                classes.contactForm__formInputFile
              )}
            >
              <>
                <div className={classes.contactForm__fileButton}>
                  {t("form.file_choose")}
                </div>
                <div className="ms-10 ps-8 mb-0 d-flex align-items-center overflow-hidden">
                  {selectedFile ? (
                    <>
                      {selectedFile.name}
                      <div className={classes.contactForm__fileDeleteButton}>
                        <TrashSVG onClick={() => setSelectedFile(null)} />
                      </div>
                    </>
                  ) : (
                    t("form.file_not_chosen")
                  )}
                </div>
              </>
            </label>
            <input
              id="cvFile"
              name="cvFile"
              type="file"
              accept=".pdf,.docx,.doc,.txt,.rtf,.odt"
              className="d-none"
              onChange={handleFileChange}
            />
          </div>
        )}
      </div>

      <div className="mb-3">
        <textarea
          name="message"
          placeholder={t("form.message")}
          rows={5}
          required
          className={classNames("form-control", classes.contactForm__textArea)}
        />
      </div>

      <div className="d-flex justify-content-start">
        <div className="mb-4 text-start md-w-50 sm-w-100">
          <div className="form-check">
            <input
              className="form-check-input"
              name="gdpr"
              type="checkbox"
              required
              id="gdpr"
            />
            <label className="form-check-label" htmlFor="gdpr">
              {t("form.gdpr")}
            </label>
            <p className="invalid-feedback m-0 fs-7">
              {t("form.gdpr_unchecked")}
            </p>
          </div>
        </div>
      </div>

      <Button
        variant="contained"
        type="submit"
        disabled={mutation.isLoading}
        className={classNames("btn", classes.contactForm__button)}
      >
        {mutation.isLoading ? (
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        ) : (
          t("form.send")
        )}
      </Button>
    </form>
  );
};

export default ContactForm;
