import Head from "next/head";
import React, { ReactNode } from "react";
import Footer from "./Footer/Footer";
import Navigation from "./utilities/Navigation/Navigation";
import { useTranslation } from "react-i18next";

interface Props {
  children: ReactNode;
  title: string;
  bgPrimary?: boolean;
  navigationClassName?: string;
}

const Layout = ({ children, title, navigationClassName, bgPrimary }: Props) => {
  const { t } = useTranslation("common");
  return (
    <>
      <Head>
        <title>{`${title} | Viable One`}</title>
        <meta name="description" content={t("meta_description")} />
      </Head>
      <Navigation bgPrimary={bgPrimary} className={navigationClassName} />
      {children}
      <Footer />
    </>
  );
};

export default Layout;
