import Link, { LinkProps } from "next/link";
import { csRoute } from "../../routes/routes.cs";

interface Props extends Omit<React.PropsWithChildren<LinkProps>, "href"> {
  href: string;
  className?: string
  style?: {}
}

const TranslatedLink = ({ children, href, ...linkProps }: Props) => {
  return (
    <Link
      href={csRoute[href] || href}
      {...linkProps}
    >
      {children}
    </Link>
  );
};

export default TranslatedLink;
