import { axiosClient } from "../../api/apiClient";

export const sendContactForm = async (formData: FormData) => {
  try {
    const response = await axiosClient.post("/contact", {
      ...(await Promise.all(
        Array.from(formData.getAll("cvFile")).map(async (value) => {
          if (value instanceof File && value.name) {
            return {
              cvFile: {
                filename: value.name,
                buffer: await readFileAsBuffer(value),
              },
            };
          }
        })
      ).then((cvFiles) => ({
        cvFiles: cvFiles.filter((cvFile) => cvFile !== undefined),
        name: formData.get("name"),
        email: formData.get("email"),
        message: formData.get("message"),
        phone: formData.get("phone"),
        gdpr: formData.get("gdpr"),
      }))),
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

async function readFileAsBuffer(file: File): Promise<Buffer> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const buffer = Buffer.from(reader.result as ArrayBuffer);
      resolve(buffer);
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
}
