import { useTranslation } from "next-i18next";

export type NavigationItem = {
  name: string;
  url: string;
};

export const useNavigationRoutes = (): NavigationItem[] => {
  const { t } = useTranslation("navigation");

  return [
    { name: t("about"), url: "/" },
    { name: t("career"), url: "/career" },
    { name: t("team"), url: "/team" },
    { name: t("case_studies"), url: "/case-studies" },
    { name: t("history"), url: "/history" }
  ];
};
