import { useEffect, useState, useCallback } from "react";

const useWindowDimensions = (): {
  width: number;
  height: number;
} => {
  const hasWindow = "undefined" !== typeof window;

  const getWindowDimensions = useCallback(() => {
    const width = hasWindow ? window.innerWidth : 0;
    const height = hasWindow ? window.innerHeight : 0;

    return {
      width,
      height,
    };
  }, [hasWindow]);

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };
    if (hasWindow) {
      window.addEventListener("resize", handleResize);
    }

    return () => {
      if (hasWindow) {
        window.removeEventListener("resize", handleResize);
      }
    };
  }, [hasWindow, getWindowDimensions]);

  return windowDimensions;
};

export default useWindowDimensions;
