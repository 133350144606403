import classNames from "classnames";
import classes from "./NavigationContent.module.scss";
import { NavigationItem, useNavigationRoutes } from "components/hooks/useNavigationRoutes";
import { useRouter } from "next/router";
import { CloseButton, Nav, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import LanguageSwitch from "../LanguageSwitch/LanguageSwitch";
import TranslatedLink from "../TranslatedLink";
import Contact from "../../Contact/Contact/Contact";
import { useDialog } from "@providers/DialogProvider";

interface Props extends React.HTMLProps<HTMLDivElement> {
  isMobile?: boolean;
  bgPrimary?: boolean;
  showContactUsButton?: boolean;
  showLanguageSwitch?: boolean;
  additionalRoutes?: NavigationItem[];
}

const NavigationContent = ({
  isMobile,
  bgPrimary,
  showContactUsButton = true,
  showLanguageSwitch = false,
  className,
  additionalRoutes
}: Props) => {
  const { t } = useTranslation("navigation");
  const { openDialog, closeDialog } = useDialog();
  const { route, pathname } = useRouter();
  const routes = useNavigationRoutes();

  const contactModalVariation =
    route === "/" || route === "/case-studies" ? "business" : "hr";

  const finalRoutes = [...routes, ...additionalRoutes || []]

  const handleOpenContactDialog = () => {
    openDialog({
      id: "navigation-contact-dialog",
      contentClassName: "bg-dark pt-5 px-5",
      content: (
        <>
          <div className="d-flex justify-content-end">
            <CloseButton
              className="btn-close-white"
              onClick={closeDialog}
            ></CloseButton>
          </div>
          <Contact
            variant={contactModalVariation}
            modal
            className="m-auto p-auto h-75"
          />
        </>
      ),
      size: "lg",
      animation: false,
      centered: true,
      className: "m-0 p-0",
      style: { minWidth: "90%" },
    });
  };

  return (
    <Nav className={className} variant="pills">
      {finalRoutes.map((navigationItem) => (
        <div
          className="my-auto mx-2 px-1"
          key={`navigation-content-${navigationItem.url}`}
        >
          <Nav.Item className="text-reset">
            <TranslatedLink
              key={navigationItem.name}
              href={navigationItem.url}
              className={classNames(
                "text-reset",
                pathname !== navigationItem.url && "text-decoration-none"
              )}
            >
              {navigationItem.name}
            </TranslatedLink>
          </Nav.Item>
          <hr className="bg-light opacity-25 my-1 d-block d-lg-none" />
        </div>
      ))}
      {showContactUsButton && (
        <div className="my-auto">
          <Button
            type="button"
            className={classNames(
              "btn mx-0 mx-lg-2 my-3 my-lg-0 align-self-center",
              classes.navigationContent__contactButton
            )}
            variant={!bgPrimary || isMobile ? "primary" : "secondary"}
            onClick={handleOpenContactDialog}
            style={{ borderRadius: "0.5rem" }}
          >
            {t("contact_us_button")}
          </Button>
        </div>
      )}
      {showLanguageSwitch && (
        <LanguageSwitch
          inverted={isMobile || !bgPrimary}
          isOffCanvas={isMobile}
          className="my-3 ms-lg-3"
        />
      )}
    </Nav>
  );
};

export default NavigationContent;
