import classNames from "classnames";
import classes from "./Contact.module.scss";
import Image from "next/legacy/image";
import { useTranslation } from "next-i18next";
import { Col, Container, Row } from "react-bootstrap";
import ContactForm, { ContactFormProps } from "../ContactForm/ContactForm";
import { ComponentPropsWithoutRef } from "react";
import MailSVG from "../../../public/icons/mail.svg";
import PhoneSVG from "../../../public/icons/phone.svg";
import LinkedinSVG from "../../../public/icons/linkedin.svg";

interface Props extends ComponentPropsWithoutRef<"div">, ContactFormProps {
  showTitlePhrase?: boolean;
  modal?: boolean;
}

const Contact = ({
  className,
  variant,
  showTitlePhrase,
  modal,
  ...htmlProps
}: Props) => {
  const { t } = useTranslation("contact");
  const contactClass = modal ? "contact" : "contactBackgroundImage";

  return (
    <div
      {...htmlProps}
      className={classNames(
        classes[contactClass],
        variant === "hr" && "h-auto",
        className
      )}
    >
      <Container>
        <Row className={"d-flex flex-column flex-lg-row"}>
          <Col className="d-flex justify-content-center flex-column">
            <div>
              <p className="m-0 text-uppercase fs-1 fw-bold">{t(`title`)}</p>
              {showTitlePhrase && (
                <>
                  <p className="mb-0">
                    {t(`contact_form.${variant}.subtitle`)}
                  </p>
                  <p className="mb-0">{t(`contact_form.${variant}.phrase`)}</p>
                </>
              )}
            </div>
            <Row className="my-3">
              <div style={{ width: "100px" }}>
                {variant === "hr" ? (
                  <div className={classes.contact__imageWrapper}>
                    <Image
                      src="/images/team/panenka.png"
                      layout="responsive"
                      width={100}
                      height={100}
                      alt="human-resources"
                      className={classes.contact__image}
                    />
                  </div>
                ) : (
                  <div className={classes.contact__imageWrapper}>
                    <Image
                      src="/images/team/dominik_mathauser.webp"
                      layout="responsive"
                      width={100}
                      height={100}
                      alt="business-development-manager"
                      className={classes.contact__imageBusiness}
                    />
                  </div>
                )}
              </div>
              <Col className="d-flex flex-column justify-content-center">
                <div>
                  <p className="m-0 fw-bold text-uppercase">
                    {t(`contact_form.${variant}.name`)}
                  </p>
                </div>
                <div>
                  <p className="m-0 fs-7">
                    {t(`contact_form.${variant}.position`)}
                  </p>
                </div>
              </Col>
            </Row>
            <div className="my-4 h-100 d-flex flex-column gap-2 fs-6 fw-light">
              <div>
                <MailSVG />
                <a
                  href={`mailto:${t(`contact_form.${variant}.email`)}`}
                  className="text-white ms-3"
                >
                  {t(`contact_form.${variant}.email`)}
                </a>
              </div>
              <div>
                <PhoneSVG />
                <a
                  href={`tel:${t(`contact_form.${variant}.phone`)}`}
                  className="text-white ms-3"
                >
                  +420 {t(`contact_form.${variant}.phone`)}
                </a>
              </div>
              <div>
                <LinkedinSVG />
                <a
                  href={t(`contact_form.${variant}.linkedin`)}
                  className="text-white ms-3"
                >
                  {t(`contact_form.${variant}.linkedin_name`)}
                </a>
              </div>
            </div>
          </Col>

          <Col
            className={classNames("d-flex justify-content-center flex-column")}
          >
            <div className={classes.contact__content}>
              <ContactForm variant={variant} />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Contact;
