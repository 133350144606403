import { useTranslation } from "next-i18next";
import LogoWhiteSVG from "../../public/logo-white-text.svg";
import classes from "./Footer.module.scss";
import { Col, Container, Row } from "react-bootstrap";
import { ReactBootstrapHTMLContainerProps } from "../../types/htmlElementTypes";
import classNames from "classnames";
import NavigationContent from "../utilities/NavigationContent/NavigationContent";
import { NavigationItem } from "components/hooks/useNavigationRoutes";

interface Props extends ReactBootstrapHTMLContainerProps { }

const Footer = ({ className, ...htmlProps }: Props) => {
  const { t } = useTranslation(["footer", "common"]);

  const additionalRoutes: NavigationItem[] = [
    {
      name: "GDPR",
      url: '/gdpr.pdf'
    }
  ]

  return (
    <div {...htmlProps} className={classes.footer}>
      <Container>
        <Row className="mb-3">
          <Col
            xs={12}
            md={6}
            className="d-flex align-items-center justify-content-center justify-content-md-start mb-3 mb-md-0"
          >
            <LogoWhiteSVG />
          </Col>
          <Col xs={12} md={6} className="text-center text-md-end mb-2 mb-md-0">
            <strong>
              Viable One s.r.o
            </strong>
            <br />
            <span>
              Jankovcova 1603/47a,<br /> Praha 7, 170 00
            </span>
            <br />
            <span>
              {`${t("cin")}: 04509030`}
            </span>
            <br />
            <span>
              {`${t("vat")}: CZ04509030`}
            </span>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={12}>
            <NavigationContent
              showContactUsButton={false}
              showLanguageSwitch={false}
              className="justify-content-center w-100"
              additionalRoutes={additionalRoutes}
            />
          </Col>
        </Row>
        <div className="text-center">
          <div className="d-flex justify-content-center">
            <div className={classNames(classes.footer__line, "mb-3")}></div>
          </div>
          {t("all_right")}
        </div>
      </Container>
    </div>
  );
};

export default Footer;
