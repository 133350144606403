import classNames from "classnames";
import { useRouter } from "next/router";
import classes from "./LanguageSwitch.module.scss";

export enum Language {
  cs = "cs",
  en = "en",
}

interface Props extends React.HTMLProps<HTMLDivElement> {
  inverted?: boolean;
  isOffCanvas?: boolean;
}

const LanguageSwitch = ({
  inverted,
  isOffCanvas,
  className,
  ...htmlProps
}: Props) => {
  const { locale, pathname, push, asPath } = useRouter();
  const changeLanguage = (language: Language) => {
    push(pathname, asPath, { locale: language });
  };

  return (
    <div
      {...htmlProps}
      className={classNames(classes.languageSwitch, className)}
    >
      <button
        className={classNames(
          classes.languageSwitch__item,
          locale === Language.cs
            ? inverted
              ? classes.languageSwitch__itemPillActive
              : classes.languageSwitch__itemPillActiveHomepage
            : inverted &&
                (isOffCanvas
                  ? classes.languageSwitch__itemPillInactive
                  : classes.languageSwitch__itemPillInactiveDesktop)
        )}
        onClick={() => changeLanguage(Language.cs)}
      >
        CZ
      </button>
      <button
        className={classNames(
          classes.languageSwitch__item,
          locale === Language.en
            ? inverted
              ? classes.languageSwitch__itemPillActive
              : classes.languageSwitch__itemPillActiveHomepage
            : inverted &&
                (isOffCanvas
                  ? classes.languageSwitch__itemPillInactive
                  : classes.languageSwitch__itemPillInactiveDesktop)
        )}
        onClick={() => changeLanguage(Language.en)}
      >
        EN
      </button>
    </div>
  );
};

export default LanguageSwitch;
