import { useDialog } from "@providers/DialogProvider";
import classNames from "classnames";
import classes from "./ContactForm.module.scss";
import React, { ComponentPropsWithoutRef } from "react";
import { Button, CloseButton, Col, Row } from "react-bootstrap";
import SuccessSVG from "../../../public/icons/success.svg";
import ErrorSVG from "../../../public/icons/error.svg";
import { useTranslation } from "react-i18next";

interface Props extends ComponentPropsWithoutRef<"div"> {
  variant: "success" | "error";
}

const ContactFormDialog = ({ className, variant, ...htmlProps }: Props) => {
  const { closeDialog } = useDialog();
  const { t } = useTranslation("contact");

  return (
    <div className={className} {...htmlProps}>
      <div className="d-flex justify-content-end">
        <CloseButton
          className="btn-close-white"
          onClick={closeDialog}
        ></CloseButton>
      </div>
      <Col className="d-flex flex-column justify-content-center my-7">
        <div className="d-flex justify-content-center align-items-center">
          <div>{variant === "success" ? <SuccessSVG /> : <ErrorSVG />}</div>
        </div>
        <Col className="text-white d-flex flex-column justify-content-center mt-6">
          <Row>
            <p className="m-0 text-center text-uppercase fs-2 fw-bold">
              {t(`form.${variant}.title`)}
            </p>
          </Row>
          <Row>
            <p className="m-0 text-center fs-4 px-4 mt-4">
              {t(`form.${variant}.subtitle`)}
            </p>
          </Row>
        </Col>
        <div className="d-flex justify-content-center">
          <Button
            variant="contained"
            className={classNames("btn my-6 w-50", classes.contactForm__button)}
            onClick={closeDialog}
          >
            {t(`form.${variant}.button`)}
          </Button>
        </div>
      </Col>
    </div>
  );
};

export default ContactFormDialog;
