import {
  Navbar,
  Container,
  Offcanvas,
  CloseButton,
  NavbarProps,
} from "react-bootstrap";
import LogoWhiteSVG from "../../../public/logo-white.svg";
import LogoSVG from "../../../public/logo.svg";
import NavigationContent from "../NavigationContent/NavigationContent";
import classes from "./Navigation.module.scss";
import classNames from "classnames";
import useWindowDimensions from "../../hooks/useWindowDimension";
import LogoWhiteYellowSVG from "../../../public/logo-white-text.svg";
import { useCallback, useEffect, useState } from "react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { useThrottledFunction } from "components/hooks/useThrottle";

interface Props extends NavbarProps {
  bgPrimary?: boolean;
}
const Navigation = ({ className, bgPrimary }: Props) => {
  const { t } = useTranslation("navigation");
  const { pathname } = useRouter();
  const { width } = useWindowDimensions();
  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);
  const handleClose = () => setIsOffcanvasOpen(false);
  const handleShow = () => setIsOffcanvasOpen(true);
  const mediumOrLower = width < 992;
  const isHomepage = pathname === "/";
  const [isNavGlass, setIsNavGlass] = useState<boolean>(isHomepage);

  let navigationClass;
  if (isHomepage) {
    if (isNavGlass) {
      navigationClass = classes.navigationMatteGlass;
    } else {
      navigationClass = classes.navigationGrayBg;
    }
  } else {
    navigationClass = bgPrimary ? "bg-primary" : "bg-white";
  }

  const percentageToTrigger = 20;

  const checkScroll = () => {
    if (typeof window !== "undefined") {
      const scrolled =
        (window.scrollY / (document.body.scrollHeight - window.innerHeight)) *
        100;

      if (isHomepage && scrolled >= percentageToTrigger) {
        setIsNavGlass(false);
      } else {
        setIsNavGlass(true);
      }
    }
  };

  const checkScrollCallback = useCallback(() => {
    checkScroll();
  }, []);

  const { throttledFn } = useThrottledFunction({
    callbackFn: checkScrollCallback,
    throttleMs: 500,
  });

  useEffect(() => {
    if (isHomepage && typeof window != undefined) {
      window.addEventListener("scroll", throttledFn);

      return () => {
        window.removeEventListener("scroll", throttledFn);
      };
    }
  }, [isHomepage, percentageToTrigger, checkScroll]);

  return (
    <Navbar
      className={classNames(
        classes.navigation,
        "pt-4 py-3 position-fixed",
        navigationClass,
        className
      )}
      expand="lg"
      variant={isHomepage ? "dark" : "light"}
    >
      <Container>
        <Navbar.Brand href="/">
          {isHomepage ? (
            <LogoWhiteYellowSVG title="Viable one s.r.o" />
          ) : bgPrimary ? (
            <LogoWhiteSVG title="Viable one s.r.o" />
          ) : (
            <LogoSVG title="Viable one s.r.o" />
          )}
        </Navbar.Brand>
        <Navbar.Toggle
          className={classes.navigation__hamburger}
          aria-controls="toggle"
          aria-label="toggle-menu"
          onClick={handleShow}
        />
        <Navbar.Offcanvas
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
          placement="end"
          className="bg-dark text-white w-100"
          show={isOffcanvasOpen && mediumOrLower}
          onHide={handleClose}
        >
          <Offcanvas.Header className="justify-content-between align-items-center p-4">
            <LogoWhiteYellowSVG className={classes.navigation__logo} />
            <div className="d-flex justify-content-end">
              <p className="m-0 mx-2 my-auto text-light">{t("close_button")}</p>
              <CloseButton
                className="btn-close-white text-reset my-auto"
                type="button"
                aria-controls="dismiss"
                onClick={handleClose}
              ></CloseButton>
            </div>
          </Offcanvas.Header>
          <hr className="bg-primary opacity-100 my-1" />
          <Offcanvas.Body className="p-4 pt-2">
            <NavigationContent
              isMobile
              className="justify-content-end flex-grow-1"
            />
          </Offcanvas.Body>
        </Navbar.Offcanvas>
        <NavigationContent
          bgPrimary={isHomepage ? isNavGlass : bgPrimary}
          className={classNames(
            "d-none d-lg-flex",
            bgPrimary && isHomepage && "text-white"
          )}
        />
      </Container>
    </Navbar>
  );
};

export default Navigation;
